<template>
  <div class="jsmb-account-information-detail">
    <div class="notification is-primary" v-if="updateUserOK">
      <h2 class="is-uppercase has-text-weight-bold is-size-5">Votre compte a été mis a jour</h2>
    </div>
    <div class="notification is-danger" v-if="!notError.updateUser">
      <h2 class="is-uppercase has-text-weight-bold is-size-5">{{ errorMsg.updateUser }}</h2>
    </div>


    <form @submit.prevent="updateUser">
      <h2 class="jsmb-title-detail is-size-4 has-text-text">Mon profil</h2>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Type de compte</label>
        </div>
        <div class="field-body">
          <div class="columns is-multiline" style="width:100%">
            <div class="column is-12 add-margin-bottom">
              <div class="control tdc">
                <label class="radio" v-for="(userType, index) in userTypes" :key="'user-type-'+index" v-show="user.user_type_id === userType.id" style="display:none">
                    <input type="radio" v-bind:id="userType.name" v-bind:value="userType.id" v-model="user.user_type_id" disabled>
                    {{ userType.name }}
                    <small>{{ userType.description }}</small>
                </label>
                <!--<label class="radio" @click="accountShow=true">
                  <input type="radio" id="Stockeur+Skipper" :value="1" v-model="user.full_feature">
                  Stockeur+Skipper
                  <small>J'ai un bateau et je propose des stockages</small>
                </label>//-->
              </div>
            </div>
            <transition name="fade">
              <div class="column is-12" v-if="accountShow===true">
                <article class="message is-info">
                  <div class="message-body">
                    <span class="icon has-text-info">
                      <i class="fas fa-info-circle"></i>
                    </span>
                    En choisissant Stockeur + Skipper, vous faites évoluer les droits de votre compte
                  </div>
                </article>
              </div>
            </transition>
          </div>
        </div>
      </div>



      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Changez votre image</label>
        </div>
        <div class="field-body">
          <div class="columns is-multiline">

            <div class="column is-12 text-photo">
              <small>Vous souhaitez changer votre photo ?</small>
              <br>
              <small>Envoyez en une nouvelle avec le champs ci-dessous</small>
            </div>

            <div class="column is-12">
              <div class="columns is-mobile is-vcentered" style="margin-bottom:1rem;">
                <div class="column is-3-desktop is-3-tablet is-6-mobile">
                  <figure class="image is-128x128">
                    <img class="is-rounded" :src="user.profil_picture_64">
                  </figure>
                </div>
                <div class="column uploadimg">
                  <b-field class="file ">
                    <b-upload v-model="imgUpload" @input="uploadProfilPicture()">
                      <a class="button is-primary"><b-icon icon="upload"></b-icon><span>Choisir un fichier</span></a>
                    </b-upload>
                  </b-field>
                </div>
              </div>
            </div>
            <p class="help is-danger" :class="{'is-hidden': notError.imgUpload}">{{errorMsg.imgUpload}}</p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Prénom <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Prénom" v-model="user.first_name" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Nom <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Nom" v-model="user.last_name" required>
            </p>
            <small>
              Sur votre profil public, seul votre prénom est visible.<br/>
              Lorsque vous faites une réservation, votre hôte voit votre prénom et votre nom.
            </small>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-if="isPro">
        <div class="field-label is-normal">
          <label class="label">Entreprise <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Entreprise" v-model="user.company" :disabled="disabled.company" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-if="isPro">
        <div class="field-label is-normal">
          <label class="label">Numéro fiscal <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Numéro fiscal" v-model="user.tax_id" :disabled="disabled.tax_id" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-if="isPro">
        <div class="field-label is-normal">
          <label class="label">Numéro de TVA <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Numéro de TVA" v-model="user.vat_id" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Sexe <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <label class="radio" v-for="(userGender, index) in userGenders" :key="'user-gender-'+index">
                <input type="radio" name="user-gender" v-bind:id="userGender.name" v-bind:value="userGender.id" v-model="user.user_gender_id" required>
                {{userGender.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Email <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="email" placeholder="Email" v-model="user.email" :disabled="disabled.email"  required>
            </p>
            <small>
                Nous ne partagerons pas votre adresse e-mail privée avec d'autres utilisateurs.
            </small>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Date de naissance <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <b-datepicker
                placeholder="Date de naissance"
                v-model="user.dob"
                required
                :first-day-of-week="1"
                :month-names="this.$store.state.datepickerLocale.monthNames"
                :day-names="this.$store.state.datepickerLocale.dayNames"
                :max-date="this.maxDateDob()"
                :focused-date="this.maxDateDob()">
              </b-datepicker>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">N° de téléphone <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="tel" pattern="^[0]{1}[0-9]{9}$" placeholder="Votre numéro de téléphone !" v-model="user.phoneWithout33" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-if="userType === 'stockeur' || fullFeature">
        <div class="field-label is-normal">
          <label class="label">Ma bio <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <textarea class="textarea" placeholder="Ma bio" rows="10" v-model="user.bio" required></textarea>
            </p>
          </div>
        </div>
      </div>

      <h2 class="jsmb-title-detail is-size-4 has-text-text">Facturation</h2>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Adresse <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre adresse" v-model="user.billing_address" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Appartement, bâtiment (facultatif)</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre complément d'adresse" v-model="user.billing_address_complement">
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Code postal <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre code postal" v-model="user.billing_zip_code" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Ville <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre ville" v-model="user.billing_city" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Pays <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre pays" v-model="user.billing_country" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-if="userType === 'stockeur' || fullFeature">
        <div class="field-label is-normal">
          <label class="label" v-if="fullFeature">IBAN Stockeur <sup class="has-text-danger has-text-weight-bold">*</sup></label>
          <label class="label" v-else>IBAN <sup class="has-text-danger has-text-weight-bold">*</sup></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Saisir votre IBAN" v-model="user.iban_stockeur" required>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal"></div>
        <div class="field-body">
          <div class="field">
            <div class="columns">
              <div class="column is-4"></div>
              <div class="column is-2"></div>
              <div class="column is-6 has-text-right">
                <button type="submit" class="button is-fullwidth is-danger" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Enregistrer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form @submit.prevent="updateUserKyc" v-if="(userType === 'stockeur' || fullFeature) && (user.stripe_person_id !== null)">
      <h2 class="jsmb-title-detail is-size-4 has-text-text">Confiance</h2>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Document d’identité Recto</label>
        </div>
        <div class="field-body">
          <b-field class="file">
            <b-upload v-model="kycFile.recto">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Choisir un fichier…</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="kycFile.recto">
              {{ kycFile.recto.name }}
            </span>
          </b-field>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Document d’identité Verso</label>
        </div>
        <div class="field-body">
          <b-field class="file">
            <b-upload v-model="kycFile.verso">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Choisir un fichier…</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="kycFile.verso">
              {{ kycFile.verso.name }}
            </span>
          </b-field>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal"></div>
        <div class="field-body">
          <div class="field">
            <div class="columns">
              <div class="column is-5"></div>
              <div class="column is-7 has-text-right">
                <button class="button is-fullwidth is-danger" type="submit">Valider votre identité</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div v-if="imgCrop !== null">
      <b-modal :active.sync="modalCrop" :width="640" scroll="keep">
        <div class="card-content" style="background:#fff;padding-bottom:1rem;">
          <h2 class="jsmb-title-photo is-size-4">Redimensionnez votre photo</h2>
          <vueCropper
            ref="cropper"
            :img="imgCrop"
            :autoCrop="true"
            :autoCropWidth="128"
            :autoCropHeight="128"
            :fixedBox="true"
            mode="cover"
          ></vueCropper>
          <div class="has-text-centered" style="margin-top:1rem">
            <button type='button' class="button is-primary" style="margin-right:1rem;" v-on:click="$refs.cropper.changeScale(-1)"> - </button>
            <button type='button' class="button is-danger" v-on:click="cropProfilPicture">Valider</button>
            <button type='button' class="button is-primary" style="margin-left:1rem;" v-on:click="$refs.cropper.changeScale(1)"> + </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { GET_USER_GENDER, GET_USER, UPDATE_USER, GET_USER_TYPE } from '@/store/modules/database/actions/database.actions'
import { GET_DATEPICKER_FR } from "@/store/modules/template/actions/template.actions"
import { VueCropper }  from "vue-cropper"
import Separator from "@/components/template/ui/Separator.vue"
import { watch } from 'fs';

export default {
  name: 'Profil',
  components: {Separator, VueCropper},

  data () {
    return {
      accountShow : false,
      isPro: JSON.parse(localStorage.getItem('is-professional')) || false,
      modalCrop: false,
      updateUserOK: false,
      userType: localStorage.getItem('user-type'),
      fullFeature: JSON.parse(localStorage.getItem('full-feature')),
      userGenders: [],
      userTypes: [],
      user: {
        first_name: '',
        last_name: '',
        company: '',
        tax_id: '',
        vat_id: '',
        user_gender_id: undefined,
        user_type_id: undefined,
        account_type_id: undefined,
        full_feature: false,
        email: '',
        dob: undefined,
        phone: '',
        phoneWithout33: '',
        billing_address: '',
        billing_address_complement: '',
        billing_zip_code: '',
        billing_city: '',
        billing_country: '',
        iban_stockeur: '',
        bio: '',
        new_profil_picture: false,
        profil_picture_64: require('./../../../assets/profil-modal.png'),
      },
      imgUpload: null,
      imgCrop: null,
      notError: {
        updateUser: true,
        imgUpload: true,
      },
      errorMsg: {
        updateUser: '',
        imgUpload: '',
      },
      kycFile: {
        recto: undefined,
        verso: undefined,
      },
      disabled: {
        email: true,
        company: false,
        tax_id: false,
      },
      stripe: undefined,
      buttonDoneLoad: false,
    }
  },
  created() {
    this.$store.dispatch(GET_DATEPICKER_FR)
    this.stripe = Stripe(this.$store.state.globalConstant.STRIPE_TOKEN_PUBLISHABLE)
    
    // Get UserGenders
    this.$store.dispatch(
      GET_USER_GENDER
    ).then(
      resp => {
        this.userGenders = resp
      }
    )
    // Get User
    this.$store.dispatch(
      GET_USER
    ).then(
      resp => {
        this.user = Object.assign(this.user, resp)
        this.user.phoneWithout33 = this.user.phone.replace('+33', '0')
        if (resp.dob !== null) {
          if (!!window.MSInputMethodContext && !!document.documentMode) {
            // IE
          }else{
            this.user.dob = new Date(resp.dob)
          }
        }
        
        if (this.user.company !== null) {
          this.disabled.company = true
        }
        if (this.user.tax_id !== null) {
          this.disabled.tax_id = true
        }
      }
    )
    // Get UserTypes
    this.$store.dispatch(
      GET_USER_TYPE
    ).then(
      resp => {
        this.userTypes = resp
      }
    )
  },
  methods: {
    Stripe_addAccount(businessType, individual, company) {
      const account = this.stripe.createToken('account', {
        business_type: businessType,
        individual: individual,
        company: company,
        tos_shown_and_accepted: true,
      })
      
      return account
    },
    Stripe_addPerson(userData, dob) {
      const person = this.stripe.createToken('person', {
        person: {
          address: {
            line1: userData.billing_address,
            city: userData.billing_city,
            state: '',
            postal_code: userData.billing_zip_code,
          },
          dob: dob,
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone: userData.phone,
          relationship: {
            account_opener: true,
            director: true,
            owner: true,
            percent_ownership: 100,
            title: ''
          }
        },
      })

      return person
    },
    updateUser () {
      let userData = JSON.parse(JSON.stringify(this.user)),
          year = this.user.dob.getFullYear(),
          month = this.user.dob.getMonth()+1,
          day = this.user.dob.getDate(),
          accountToken = null,
          personToken = null
      this.buttonDoneLoad = true

      if (userData.profil_picture === null && !userData.new_profil_picture) {
        this.updateUserError (null, 'Attention ! La photo de profil est obligatoire pour valider totalement votre compte. Merci de la renseigner.')
      }else{
        if (month < 10) {
          month = '0'+month
        }
        if (day < 10) {
          day = '0'+day
        }
        userData.dob = year+'-'+month+'-'+day
        switch (this.userType) {
          case "stockeur":
            let vueThis = this,
                businessType = '',
                individual = {},
                company = {}
            if (this.isPro) {
              businessType = 'company'
              company = {
                name: userData.company,
                phone: userData.phone,
                address: {
                  line1: userData.billing_address,
                  city: userData.billing_city,
                  state: '',
                  postal_code: userData.billing_zip_code,
                },
                tax_id: userData.tax_id,
                vat_id: userData.vat_id,
              }
            }else{
              businessType = 'individual'
              individual = {
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                phone: userData.phone,
                address: {
                  line1: userData.billing_address,
                  city: userData.billing_city,
                  state: '',
                  postal_code: userData.billing_zip_code,
                },
                dob: {
                  day: this.user.dob.getDate(),
                  month: this.user.dob.getMonth()+1,
                  year: this.user.dob.getFullYear(),
                },
              }
            }
            const account = this.Stripe_addAccount(businessType, individual, company)
            account.then(function(respAccount) {
              accountToken = respAccount.token.id
              if (vueThis.isPro) {
                const person = vueThis.Stripe_addPerson(userData, { day: day, month: month, year: year })
                person.then(function(respPerson) {
                  personToken = respPerson.token.id
                  // Update User
                  vueThis.$store.dispatch(
                    UPDATE_USER, {user: userData, accountToken: accountToken, personToken: personToken}
                  ).then(
                    resp => vueThis.updateUserResp(resp)
                  ).catch(
                    error => vueThis.updateUserError(error)
                  )
                })
              }else{
                // Update User
                vueThis.$store.dispatch(
                  UPDATE_USER, {user: userData, accountToken: accountToken}
                ).then(
                  resp => vueThis.updateUserResp(resp)
                ).catch(
                  error => vueThis.updateUserError(error)
                )
              }
            }).catch(function(error) {
              console.log(error)
            })
            break;

          case "skipper":
            if (this.fullFeature) {
              let vueThis = this,
                businessType = '',
                individual = {},
                company = {}
              if (this.isPro) {
                businessType = 'company'
                company = {
                  name: userData.company,
                  phone: userData.phone,
                  address: {
                    line1: userData.billing_address,
                    city: userData.billing_city,
                    state: '',
                    postal_code: userData.billing_zip_code,
                  },
                  tax_id: userData.tax_id,
                  vat_id: userData.vat_id,
                }
              }else{
                businessType = 'individual'
                individual = {
                  first_name: userData.first_name,
                  last_name: userData.last_name,
                  email: userData.email,
                  phone: userData.phone,
                  address: {
                    line1: userData.billing_address,
                    city: userData.billing_city,
                    state: '',
                    postal_code: userData.billing_zip_code,
                  },
                  dob: {
                    day: this.user.dob.getDate(),
                    month: this.user.dob.getMonth()+1,
                    year: this.user.dob.getFullYear(),
                  },
                }
              }

              const account = this.Stripe_addAccount(businessType, individual, company)
              account.then(function(respAccount) {
                accountToken = respAccount.token.id
                if (vueThis.isPro) {
                  const person = vueThis.Stripe_addPerson(userData, { day: day, month: month, year: year })
                  person.then(function(respPerson) {
                    personToken = respPerson.token.id
                    // Update User
                    vueThis.$store.dispatch(
                      UPDATE_USER, {user: userData, accountToken: accountToken, personToken: personToken}
                    ).then(
                      resp => vueThis.updateUserResp(resp)
                    ).catch(
                      error => vueThis.updateUserError(error)
                    )
                  })
                }else{
                  // Update User
                  vueThis.$store.dispatch(
                    UPDATE_USER, {user: userData, accountToken: accountToken}
                  ).then(
                    resp => vueThis.updateUserResp(resp)
                  ).catch(
                    error => vueThis.updateUserError(error)
                  )
                }
              })
            }else{
              // Update User
              this.$store.dispatch(
                UPDATE_USER, {user: userData, accountToken: null, personToken: null}
              ).then(
                resp => this.updateUserResp(resp)
              ).catch(
                error => vueThis.updateUserError(error)
              )
            }
            break;
        
          default:
            // Update User
            this.$store.dispatch(
              UPDATE_USER, {user: userData, accountToken: null, personToken: null}
            ).then(
              resp => this.updateUserResp(resp)
            ).catch(
              error => vueThis.updateUserError(error)
            )
            break;
        }
      }
    },
    updateUserResp (resp) {
      this.updateUserOK = true
      this.notError.updateUser = true
      document.body.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      setTimeout(() => {
        this.updateUserOK = false
        this.buttonDoneLoad = false
        if (resp.reloadPage) {
          localStorage.setItem('full-feature', resp.user.full_feature)
          location.reload()
        }
      }, 1500)
    },
    updateUserError (error, msg = 'Votre compte n\'a pas été mis a jour') {
      switch (error) {
        case 'We couldn\'t find the bank for this account number':
          msg = "L'IBAN saisi n'est pas valide, veuillez le corriger ou en saisir un autre.";
          break;
      }
      this.notError.updateUser = false
      this.errorMsg.updateUser = msg
      document.body.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      this.buttonDoneLoad = false
    },
    uploadProfilPicture () {
      var vueThis = this;

      if(window.FileReader) {
        var file  = vueThis.imgUpload
        vueThis.imgUpload = null
        var reader = new FileReader()

        if (file && file.type.match('image.*')) {
          vueThis.notError.imgUpload = true
          vueThis.errorMsg.imgUpload = ''
          reader.readAsDataURL(file)
          reader.onloadend = function () {
            vueThis.imgCrop = reader.result
            vueThis.modalCrop = true
          }
        } else {
          vueThis.notError.imgUpload = false
          vueThis.errorMsg.imgUpload = 'Veuillez envoyer seulement des fichiers image.'
        }
      }
    },
    cropProfilPicture() {
      this.$refs.cropper.getCropData((data) => {
        this.user.new_profil_picture = true
        this.user.profil_picture_64 = data
        this.imgCrop = null
      })
    },
    maxDateDob() {
      let d = new Date()
      d.setFullYear(d.getFullYear()-13)
      return d
    }
  },
  watch: {
    accountShow () {
      if (this.accountShow) {
        setTimeout(() => {
          this.accountShow = false
        }, 1500)
      }
    },
    'user.phoneWithout33': {
      handler: function(newValue, oldValue) {
        this.user.phone = newValue.replace('0','+33')
      },
      deep: true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .file-name {
    width: 100%;
    max-width: 100%;
  }
  .vue-cropper {
    width: 300px;
    min-height: 300px;
    margin: 0 auto;
  }
  input:not([type="radio"]){
    margin-bottom: 0.5rem;
    border:none;
    box-shadow: none;
  }
  input[type="radio"] {
    margin-bottom: 0;
  }
  textarea {
    box-shadow: none;
    border:none
  }
  input[type=radio] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
  }
  input[type=radio]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  input[type=radio]:focus {
    outline-color: none;
  }
  .radio {
    font-size:0.85rem
  }
  .jsmb-photo {
    margin-bottom: 2rem;
  }
  .jsmb-fichier-photo {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
  .text-photo {
    padding-top: 0.375em;
    margin-bottom: 1rem;
  }
  .jsmb-title-photo {
    font-weight: bold;
    text-transform: uppercase;
    padding:1rem;
    background: #0ec0b7;
    color:#fff;
    margin-bottom: 1rem;
  }
  .card-content {
    padding: 0;
  }
  .card .media {
    margin-bottom: 0;
  }
  .tdc {
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 0.6rem;
  }
  .tdc small {
    display: block;
  }
  .add-margin-bottom {
    margin-bottom: 2rem;
  }
  .message-body {
    padding:1.0em 1.25em
  }

  .uploadimg {
    display: flex;
    justify-content: flex-end;
  }
</style>
